// // CLONACION.VUE

<template>
  <v-layout id="clonacion-permisos" v-if="clonando" v-bind="ent_clonacion" wrap>
    <v-flex xs12>
      <!-- Paso 1 de la clonación -->
      <v-card v-bind="ent_clonacionPaso1" class="paso1" v-if="paso === 1">
        <v-card-title> {{ ent_clonacionPaso1.titulo }} </v-card-title>
        <v-card-actions>
          <v-chip v-bind="ent_clonacionBtra" @click="cancelar()">
            Cancelar
          </v-chip>
          <v-chip v-bind="ent_clonacionBtra" @click="anterior()">
            Anterior
          </v-chip>
          <v-chip v-bind="ent_clonacionBtra" @click="siguiente()">
            Siguiente
          </v-chip>
        </v-card-actions>
      </v-card>

      <!-- Paso 2 de la clonación -->
      <v-card v-bind="ent_clonacionPaso2" class="paso2" v-else-if="paso === 2">
        <v-card-title> {{ ent_clonacionPaso2.titulo }} </v-card-title>
        <v-card-actions>
          <v-layout wrap>
            <v-flex xs12>
              <v-select
                v-model="clonacion_seleccionada"
                :items="items"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-flex>

            <v-flex xs12>
              <v-chip v-bind="ent_clonacionBtra" @click="cancelar()">
                Cancelar
              </v-chip>
              <v-chip v-bind="ent_clonacionBtra" @click="anterior()">
                Anterior
              </v-chip>
              <v-chip v-bind="ent_clonacionBtra" @click="siguiente()">
                Siguiente
              </v-chip>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>

      <!-- Paso 3 de la clonación -->
      <v-card v-bind="ent_clonacionPaso3" class="paso3" v-else-if="paso === 3">
        <v-card-title>
          {{ titulos_paso3[clonacion_seleccionada] }}
        </v-card-title>
        <v-card-actions>
          <v-chip v-bind="ent_clonacionBtra" @click="cancelar()">
            Cancelar
          </v-chip>
          <v-chip v-bind="ent_clonacionBtra" @click="anterior()">
            Anterior
          </v-chip>
          <v-chip v-bind="ent_clonacionBtra" @click="clonar()">
            Siguiente
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["clonando", "chip_seleccionado", "cfg"],
  data() {
    return {
      // variables de configuracion
      ent_clonacion: this.cfg,
      ent_clonacionPaso1: this.cfg.paso1,
      ent_clonacionPaso2: this.cfg.paso2,
      ent_clonacionPaso3: this.cfg.paso3,
      ent_clonacionBtra: this.cfg.btra,

      // variables del componente
      paso: 1,
      clonacion_seleccionada: 0,
      items: [
        { id: 0, name: "Todos los elementos" },
        { id: 1, name: "Todos los elementos de un departamento" },
        { id: 2, name: "Todos los elementos de un nivel" },
        { id: 3, name: "Seleccion individual" }
      ],

      titulos_paso3: [
        "3. Acepta o Cancela la clonación",
        "3. Selecciona los departamentos a clonar",
        "3. Selecciona los niveles a clonar",
        "3. Selecciona, individualmente, los elementos a clonar"
      ]
    };
  },

  methods: {
    // voy al paso siguiente de la clonación
    siguiente() {
      if (this.chip_seleccionado === null) return;
      if (!this.chip_seleccionado.seleccionado) return;

      this.paso++;
      if (this.paso === 3 && this.clonacion_seleccionada === 0)
        this.$emit("selecciono_todosPermisos");
    },

    // voy al paso anterior de la clonación
    anterior() {
      if (this.paso === 1) return;

      this.paso--;
      if (this.paso === 2) this.$emit("deselecciono_todosPermisos");
    },

    // cancelo la clonación. NO reemplazo permisos
    cancelar() {
      this.clonacion_fin("cancelar");
    },

    // pulso en finalizar la clonación. Reemplazo permisos
    clonar() {
      this.clonacion_fin("guardar");
    },

    // inicializo variables clonación y emito evento de fin de
    clonacion_fin(accion) {
      this.$emit("fin_clonacion", accion);
      this.clonacion_seleccionada = 0;
      this.paso = 1;
    }
  }
};
</script>
